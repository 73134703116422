<template>
  <div class="customer-profiling-details-header">
    <div class="customer-profiling-details-header__left-section">
      <div
        class="customer-profiling-details-header__return-button"
        @click="goBack"
      >
        <Icon
          class="customer-profiling-details-header__return-icon"
          name="chevron-left"
        />
      </div>
      <div
        v-if="!isLoading"
        class="customer-profiling-details-header__nickname"
        @click="showNicknameModal"
      >
        <div
          :class="[
            'customer-profiling-details-header__nickname-label',
            { 'customer-profiling-details-header__nickname-label--is-placeholder': isNicknamePlaceholder },
          ]"
        >
          {{ nickname }}
        </div>
        <div
          v-if="isFound"
          class="customer-profiling-details-header__nickname-button"
        >
          <Icon
            class="customer-profiling-details-header__nickname-icon"
            name="edit"
          />
        </div>
      </div>
    </div>
    <div class="customer-profiling-details-header__right-section">
      <Button
        :disabled="isLoading || isReloading || !isFound"
        :icon="isFlagged ? 'flag-fill' : 'flag'"
        @click="toggleIsFlagged"
      >
        {{ isFlagged ? 'Flagged' : 'Flag' }} customer
      </Button>
      <span
        class="customer-profiling-details-header__separator"
      />
      <Button
        icon="refresh-ccw-1"
        :loading="isReloading"
        :disabled="isLoading"
        @click="reloadCustomerProfile"
      >
        Reload data
      </Button>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';

export default {
  components: {
    Icon,
    Button,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const isLoading = computed(() => store.getters['betTicker/customerIsInitializing']);
    const isReloading = computed(() => store.getters['betTicker/customerIsLoading']);
    const isFound = computed(() => !!store.getters['betTicker/customerData']);
    const nickname = computed(() => {
      if (!isFound.value) return '';
      return store.getters['betTicker/customerData']?.nickname || 'Add a nickname';
    });
    const isNicknamePlaceholder = computed(() => !store.getters['betTicker/customerData']?.nickname);
    const isFlagged = computed(() => store.getters['betTicker/customerData']?.isFlagged);

    const goBack = () => {
      try {
        const returnQuery = JSON.parse(route.query.return);
        router.push({
          name: 'customer-profiling-list',
          query: returnQuery,
        });
      } catch {
        router.push({
          name: 'customer-profiling-list',
        });
      }
    };
    const showNicknameModal = () => {
      store.dispatch('betTicker/openCustomerEditNicknameModal');
    };
    const toggleIsFlagged = () => {
      store.dispatch('betTicker/toggleCustomerProfileDetailsIsFlagged');
    };
    const reloadCustomerProfile = () => {
      store.dispatch('betTicker/reloadCustomer');
    };

    return {
      isLoading,
      isReloading,
      isFound,
      nickname,
      isNicknamePlaceholder,
      isFlagged,
      goBack,
      showNicknameModal,
      toggleIsFlagged,
      reloadCustomerProfile,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  padding: 16px;

  &__left-section,
  &__right-section {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 4px;
    overflow: hidden
  }

  &__left-section {
    width: 100%;
  }

  &__right-section {
    width: 500px;
    justify-content: flex-end;
  }

  &__separator {
    display: block;
    background: #F0F0F0;
    width: 1px;
    min-width: 1px;
    max-width: 1px;
    height: 100%;
    margin: 0 8px;
  }

  &__return {
    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      min-width: 24px;
      max-width: 24px;
      height: 24px;
      min-height: 24px;
      max-height: 24px;
      cursor: pointer;
    }

    &-icon {
      width: 16px;
      min-width: 16px;
      max-width: 16px;
      height: 16px;
      min-height: 16px;
      max-height: 16px;
      stroke: #191414;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__nickname {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    overflow: hidden;
    cursor: pointer;

    &-label {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 19.8px;
      line-height: 29.7px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &--is-placeholder {
        color: #A9A9A9;
      }
    }

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      min-width: 32px;
      max-width: 32px;
      height: 32px;
      min-height: 32px;
      max-height: 32px;
    }

    &-icon {
      width: 16px;
      min-width: 16px;
      max-width: 16px;
      height: 16px;
      min-height: 16px;
      max-height: 16px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
