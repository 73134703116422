<template>
  <div class="customer-profiling-details">
    <CustomerProfilingDetailsHeader />
    <div
      v-if="isInitializing"
      class="customer-profiling-details__container"
    >
      <Spinner />
    </div>
    <div
      v-else-if="isNotFound"
      class="customer-profiling-details__container"
    >
      <EmptyState
        v-if="!isInitializing && !customer"
        class="empty-state"
        message="Customer not found"
      />
    </div>
    <div
      v-else
      class="customer-profiling-details__grid"
    >
      <CustomerProfilingDetailsInfo />
      <CustomerProfilingDetailsStatistics
        :customer-profile="customer"
      />
      <CustomerProfilingDetailsBets />
      <CustomerProfilingDetailsNicknameModal
        v-if="isNicknameModalOpen"
        :model-value="customer.nickname"
        @update:model-value="setCustomerNickname"
        @close="closeNicknameModal"
      />
      <CustomerProfilingDetailsSidebar />
    </div>
  </div>
</template>

<script>
import {
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { transformFilters } from '@/services/helpers/filters';
import { deserializeCustomerDetailsFilterQuery } from '@/services/helpers/customer-profiling';
import Spinner from '@/components/common/Spinner';
import EmptyState from '@/components/common/EmptyState';
import CustomerProfilingDetailsHeader from './CustomerProfilingDetailsHeader';
import CustomerProfilingDetailsInfo from './CustomerProfilingDetailsInfo';
import CustomerProfilingDetailsStatistics from './CustomerProfilingDetailsStatistics';
import CustomerProfilingDetailsBets from './CustomerProfilingDetailsBets';
import CustomerProfilingDetailsNicknameModal from './CustomerProfilingDetailsNicknameModal';
import CustomerProfilingDetailsSidebar from './CustomerProfilingDetailsSidebar';

export default {
  components: {
    Spinner,
    EmptyState,
    CustomerProfilingDetailsHeader,
    CustomerProfilingDetailsInfo,
    CustomerProfilingDetailsStatistics,
    CustomerProfilingDetailsBets,
    CustomerProfilingDetailsNicknameModal,
    CustomerProfilingDetailsSidebar,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const id = computed(() => route.params.customerId);
    const operator = computed(() => route.query.operator);
    const isInitializing = computed(() => store.getters['betTicker/customerIsInitializing']);
    const customer = computed(() => store.getters['betTicker/customerData']);
    const isNotFound = computed(() => !isInitializing.value && !customer.value);
    const isNicknameModalOpen = computed(() => store.getters['betTicker/customerEditNickname']);

    const setCustomerNickname = (newNickname) => {
      store.dispatch('betTicker/applyCustomerNickname', newNickname);
    };
    const closeNicknameModal = () => {
      store.dispatch('betTicker/closeCustomerEditNicknameModal');
    };

    onMounted(async () => {
      const allDeserializedFilters = await deserializeCustomerDetailsFilterQuery(route.query.filter);
      const allFilters = await transformFilters(allDeserializedFilters);
      store.dispatch('betTicker/initCustomer', {
        id: id.value,
        operator: operator.value,
        filters: allFilters,
      });
    });
    watch(
      () => route.query.filter,
      async (newFilterQuery) => {
        const newDeserializedFilters = await deserializeCustomerDetailsFilterQuery(newFilterQuery);
        const newFilters = await transformFilters(newDeserializedFilters);
        store.dispatch('betTicker/loadCustomer', {
          id: id.value,
          operator: operator.value,
          filters: newFilters,
        });
      },
    );
    onBeforeUnmount(() => {
      store.dispatch('betTicker/clearCustomer');
    });

    return {
      isInitializing,
      customer,
      isNotFound,
      isNicknameModalOpen,
      setCustomerNickname,
      closeNicknameModal,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;

  &__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__grid {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: 337px 1fr;
    width: 100%;
    height: calc(100% - 79px);
    padding: 0 16px;
    gap: 16px;

    .customer-profiling-details-info {
      grid-row: 1 / 3;
    }

    .customer-profiling-details-bets {
      grid-column: 2 / 3;
    }

    @media screen and (min-width: 1440px) {
      grid-template-rows: 242px 1fr;
    }
  }
}
</style>
