<template>
  <div class="customer-profiling-details-statistics">
    <div class="customer-profiling-details-statistics__frame">
      <div class="customer-profiling-details-statistics__header">
        <span class="customer-profiling-details-statistics__title">
          Statistics
        </span>
      </div>
      <div class="customer-profiling-details-statistics__container">
        <div class="customer-profiling-details-statistics__grid">
          <div class="customer-profiling-details-statistics__cell">
            <span class="customer-profiling-details-statistics__label">
              Hold
            </span>
            <span class="customer-profiling-details-statistics__value">
              {{ hold }}
            </span>
          </div>
          <div class="customer-profiling-details-statistics__cell">
            <span class="customer-profiling-details-statistics__label">
              Edge
            </span>
            <span class="customer-profiling-details-statistics__value">
              {{ edge }}
            </span>
          </div>
          <div class="customer-profiling-details-statistics__cell">
            <span class="customer-profiling-details-statistics__label">
              Single bets (%)
            </span>
            <span class="customer-profiling-details-statistics__value">
              {{ singleBets }}
            </span>
          </div>
          <div class="customer-profiling-details-statistics__cell">
            <span class="customer-profiling-details-statistics__label">
              Volume
            </span>
            <span class="customer-profiling-details-statistics__value">
              {{ volume }}
            </span>
          </div>
          <div class="customer-profiling-details-statistics__cell">
            <span class="customer-profiling-details-statistics__label">
              Profit & Loss
            </span>
            <span class="customer-profiling-details-statistics__value">
              {{ pnl }}
              <Icon
                v-if="pnlIcon"
                :name="pnlIcon"
              />
            </span>
          </div>
          <div class="customer-profiling-details-statistics__cell">
            <span class="customer-profiling-details-statistics__label">
              Average stake
            </span>
            <span class="customer-profiling-details-statistics__value">
              {{ averageStake }}
            </span>
          </div>
        </div>
        <div class="customer-profiling-details-statistics__grid">
          <CustomerProfilingDetailsStatisticsInfographic
            title="Sport"
            :model-value="sportModel"
          />
          <CustomerProfilingDetailsStatisticsInfographic
            title="Bet types"
            :model-value="betTypeModel"
          />
          <CustomerProfilingDetailsStatisticsInfographic
            title="Event state"
            :model-value="eventStateModel"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isNil, find } from 'lodash';
import { computed } from 'vue';
import Icon from '@/components/common/Icon';
import CustomerProfilingDetailsStatisticsInfographic from './CustomerProfilingDetailsStatisticsInfographic';

const formatPercentage = (value, decimals = 0) => {
  if (isNil(value)) return '-';
  const number = value * 100;
  return `${number.toFixed(decimals)}%`;
};

const formatCurrency = (value, decimals = 0) => {
  if (isNil(value)) return '-';
  const formattedNumber = value.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `$${formattedNumber}`;
};

export default {
  components: {
    Icon,
    CustomerProfilingDetailsStatisticsInfographic,
  },
  props: {
    customerProfile: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const hold = computed(() => formatPercentage(props.customerProfile?.holdPercentage, 0));
    const edge = computed(() => formatPercentage(props.customerProfile?.edgePercentage, 2));
    const singleBets = computed(() => formatPercentage(props.customerProfile?.singlesPercentage, 0));
    const volume = computed(() => formatCurrency(props.customerProfile?.volume, 2));
    const pnl = computed(() => formatCurrency(props.customerProfile?.pnl, 2));
    const pnlIcon = computed(() => {
      const pnlValue = props.customerProfile?.pnl || 0;
      if (pnlValue < 0) return 'indicator-negative';
      if (pnlValue > 0) return 'indicator-positive';
      return '';
    });
    const averageStake = computed(() => formatCurrency(props.customerProfile?.averageBetSize, 0));
    const sportModel = computed(() => ({
      id: 'sport',
      labels: ['A.Football', 'Basketball', 'Baseball', 'Hockey'],
      datasets: [{
        backgroundColor: ['#175FFF', '#FFC531', '#00BC57', '#FF2E2D'],
        data: [
          (find(props.customerProfile?.sportKpis?.nodes || [], { sportId: '841e188b-0dcf-4f5f-974f-aa52c8cec95b' })?.sportPartition || 0) * 100,
          (find(props.customerProfile?.sportKpis?.nodes || [], { sportId: 'cf64a1fd-9982-48f7-a2e4-0897cc8c668f' })?.sportPartition || 0) * 100,
          (find(props.customerProfile?.sportKpis?.nodes || [], { sportId: '4e8eca10-6afa-44ed-af77-42414ec45cb3' })?.sportPartition || 0) * 100,
          (find(props.customerProfile?.sportKpis?.nodes || [], { sportId: 'db5e8b75-30a3-4a97-9112-f28b8e962887' })?.sportPartition || 0) * 100,
        ],
      }],
    }));
    const betTypeModel = computed(() => ({
      id: 'bet-types',
      labels: ['Single', 'Parlay', 'Multiple'],
      datasets: [{
        backgroundColor: ['#175FFF', '#FFC531', '#00BC57'],
        data: [
          (find(props.customerProfile?.betTypeKpis?.nodes || [], { betType: 'SINGLE' })?.betTypePartition || 0) * 100,
          (find(props.customerProfile?.betTypeKpis?.nodes || [], { betType: 'SAME_GAME_PARLAY' })?.betTypePartition || 0) * 100,
          (find(props.customerProfile?.betTypeKpis?.nodes || [], { betType: 'MULTIPLE' })?.betTypePartition || 0) * 100,
        ],
      }],
    }));
    const eventStateModel = computed(() => ({
      id: 'event-state',
      labels: ['Pregame', 'In-play'],
      datasets: [{
        backgroundColor: ['#175FFF', '#FFC531'],
        data: [
          (find(props.customerProfile?.eventStateKpis?.nodes || [], { inPlay: false })?.inPlayPartition || 0) * 100,
          (find(props.customerProfile?.eventStateKpis?.nodes || [], { inPlay: true })?.inPlayPartition || 0) * 100,
        ],
      }],
    }));

    return {
      hold,
      edge,
      singleBets,
      volume,
      pnl,
      pnlIcon,
      averageStake,
      sportModel,
      betTypeModel,
      eventStateModel,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-statistics {
  width: 100%;

  &__frame {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 16px;
    border-bottom: 1px solid #F0F0F0;
  }

  &__title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 15.72px;
    line-height: 23.58px;
    color: #191414;
  }

  &__container {
    display: flex;
    padding: 16px;
    width: 100%;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
  }

  &__cell {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
  }

  &__label {
    display: block;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 10px;
    line-height: 11.85px;
    text-transform: uppercase;
    color: #6D6D6D;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__value {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 15.72px;
    line-height: 23.58px;
    color: #191414;

    .icon {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
