<template>
  <div class="customer-profiling-details-info">
    <div class="customer-profiling-details-info__container">
      <div class="customer-profiling-details-info__header">
        <span class="customer-profiling-details-info__title">
          Customer details
        </span>
      </div>
      <div class="customer-profiling-details-info__section">
        <div class="customer-profiling-details-info__section-header">
          <span class="customer-profiling-details-info__section-title">
            Customer info
          </span>
        </div>
        <div class="customer-profiling-details-info__form-group">
          <div class="customer-profiling-details-info__form-label">
            From client:
          </div>
          <div class="customer-profiling-details-info__form-control">
            <Icon
              v-if="operatorIcon"
              class="operator-icon"
              :name="operatorIcon"
            />
            {{ operatorId }}
          </div>
        </div>
        <div class="customer-profiling-details-info__form-group">
          <div class="customer-profiling-details-info__form-label">
            User ID:
          </div>
          <div class="customer-profiling-details-info__form-control">
            <span class="customer-profiling-details-info__form-value">
              {{ customerId }}
            </span>
          </div>
        </div>
        <div class="customer-profiling-details-info__form-group">
          <div class="customer-profiling-details-info__form-label">
            Strength:
          </div>
          <div class="customer-profiling-details-info__form-control">
            <CustomerProfilingDetailsInfoStrengthSelect
              :model-value="strength"
              @update:model-value="setStrength"
            />
          </div>
        </div>
      </div>
      <div class="customer-profiling-details-info__section">
        <div class="customer-profiling-details-info__section-header">
          <span class="customer-profiling-details-info__section-title">
            Account info
          </span>
        </div>
        <div class="customer-profiling-details-info__form-group">
          <div class="customer-profiling-details-info__form-label">
            First bet placed:
          </div>
          <div class="customer-profiling-details-info__form-control">
            {{ firstBetPlaced }}
          </div>
        </div>
        <div class="customer-profiling-details-info__form-group">
          <div class="customer-profiling-details-info__form-label">
            Last active:
          </div>
          <div class="customer-profiling-details-info__form-control">
            {{ lastActive }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { getIconByOperator } from '@/services/helpers/super-admin';
import Icon from '@/components/common/Icon';
import CustomerProfilingDetailsInfoStrengthSelect from './CustomerProfilingDetailsInfoStrengthSelect';

const formatDateTime = (dateTime) => {
  if (!dateTime) return '-';
  return format(zonedTimeToUtc(dateTime, 'UTC'), 'MMMM d, yyyy');
};

export default {
  components: {
    Icon,
    CustomerProfilingDetailsInfoStrengthSelect,
  },
  setup() {
    const store = useStore();

    const operatorId = computed(() => store.getters['betTicker/customerData']?.operatorId || '-');
    const operatorIcon = computed(() => getIconByOperator(operatorId.value));
    const customerId = computed(() => store.getters['betTicker/customerData']?.id || '-');
    const strength = computed(() => store.getters['betTicker/customerData']?.strength || 0);
    const firstBetPlaced = computed(() => formatDateTime(store.getters['betTicker/customerData']?.firstPlacedBet));
    const lastActive = computed(() => formatDateTime(store.getters['betTicker/customerData']?.lastActive));

    const setStrength = (newStrength) => {
      store.dispatch('betTicker/setCustomerProfileDetailsStrength', newStrength);
    };

    return {
      operatorId,
      operatorIcon,
      customerId,
      strength,
      firstBetPlaced,
      lastActive,
      setStrength,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-details-info {
  width: 300px;
  min-width: 300px;
  max-width: 300px;

  &__container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
  }

  &__title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 15.72px;
    line-height: 23.58px;
    color: #191414;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &-header {
      display: flex;
      align-items: center;
      position: relative;

      &::after {
        content: '';
        background-color: #F0F0F0;
        position: absolute;
        top: 50%;
        right: 0;
        width: 100%;
        height: 1px;
        transform: translateY(-50%);
      }
    }

    &-title {
      position: relative;
      display: block;
      background-color: #fff;
      color: #175FFF;
      font-family: 'Rubik', sans-serif;
      font-size: 10px;
      font-weight: 600;
      line-height: 11.85px;
      text-transform: uppercase;
      z-index: 50;
      padding-right: 4px;
    }
  }

  &__form {
    &-group {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
    }

    &-label {
      font-family: 'Rubik', sans-serif;
      font-weight: 600;
      font-size: 10px;
      line-height: 11.85px;
      text-transform: uppercase;
      color: #6D6D6D;
      width: 100%;
    }

    &-control {
      display: flex;
      align-items: center;
      gap: 4px;
      color: #6D6D6D;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #191414;
      width: 100%;

      .operator-icon {
        width: 24px;
        height: 24px;
      }
    }

    &-value {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
